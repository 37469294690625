import React from 'react';
import { Link, graphql, useStaticQuery } from 'gatsby';
import _ from 'lodash';
import {ListGroup, Collapse} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';

import './LatestPosts.css';

const LatestPosts = () => {
  const [open, setOpen] = React.useState(false)
  const data = useStaticQuery(
    graphql`
      query {
        allMarkdownRemark(
          sort: { fields: [frontmatter___date], order: DESC }
          limit: 5
          filter: { frontmatter: { title: { ne: "About" } } }
        ) {
          edges {
            node {
              fields {
                slug
              }
              frontmatter {
                date(formatString: "D MMM YYYY")
                title
                tags
              }
            }
          }
        }
      }
    `
  );
  return (
    <>
      <div>
        <div onClick={e => setOpen(!open)}>
          <h4 style={{ textAlign: 'center' }}>
            Latest Posts &nbsp;
            {open ? (
              <FontAwesomeIcon icon={faMinus} size="xs" />
            ) : (
              <FontAwesomeIcon icon={faPlus} size="xs" />
            )}
          </h4>
        </div>
        <div>
          <Collapse in={open}>
            <ListGroup variant="flush">
              {data.allMarkdownRemark.edges.map(({ node }) => {
                const title = _.get(node, 'frontmatter.title');
                const slug = _.get(node, 'fields.slug');
                const date = _.get(node, 'frontmatter.date');
                const tags = _.get(node, 'frontmatter.tags');
                return (
                  <ListGroup.Item key={`${slug}-${date}`}>
                    <Link to={slug} className="postTitle">
                      {title}
                    </Link> | <a className="postDate">{date}</a>
                  </ListGroup.Item>
                );
              })}
            </ListGroup>
          </Collapse>
        </div>
      </div>
    </>
  );
}

export default LatestPosts;
