import React from 'react';
import { Icon } from 'antd';

class Footer extends React.Component {
  render() {
    return <footer>© Katie Leonard {new Date().getFullYear()}</footer>;
  }
}

export default Footer;
